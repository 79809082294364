<template>
  <div class="school">
    <div class="content">
      <div class="search">
        <van-search
          v-model="search_ward"
          show-action
          shape="round"
          placeholder="请输入搜索关键词"
        >
          <template #action>
            <div @click="onSearch" class="seachStyle">搜索</div>
          </template>
        </van-search>
      </div>
      <div class="hotRecommend" v-if="product_list.length>0">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" :immediate-check="false" :offset="1" @load="productLoad" >
          <vaeRecommend :recList="product_list" @checkDetail="downloadApp" ></vaeRecommend>
        <!-- <div class="itemContent">
          <div
            class="item"
            @click="downloadApp(item.id)"
            v-for="(item, index) in product_list"
            :key="index"
          >
            <img :src="item.main_imgs" class="goods" alt="" />
            <div class="goods-info">
              <div class="goods-title two">{{item.title}}</div>
            </div>
          </div>
        </div> -->
        </van-list>
      </div>
      <div v-else><van-empty description="暂无更多内容" /></div>
    </div>
  </div>
</template>

<script>
import { cateList,catezList } from '@/api/studentProduct'
import vaeRecommend from '@/components/hotRecommend.vue'
export default {
  components: {
    vaeRecommend
  },
  data () {
    return {
      search_ward: '',
      planList:[],
      product_list: [],
      cate_list:[],
      is_show: true,
      startPage:1,
      listObj:{
        type:3,
        page:1,
        search_word:''
      },
      loading: false,
      finished: false,
      countAll:null
      
    }
  },
  created () {
    this.getList(this.startPage)
    this. getCatezList(this.listObj)
  },
  watch: {
    search_ward: {
      handler (val) {
        if (val == '') {
          this.getList(this.startPage)
          this. getCatezList(this.listObj)
        }
      }
    }
  },
  methods: {
    async getList (page) {
      const res = await cateList({ type: 3 ,page:page})
      if (res.data.code == 200) {
        this.cate_list = res.data.data.cate_list
        // this.product_list = res.data.data.product_list
      }
    },
    async getCatezList (obj) {
      const res = await catezList(obj)
      if (res.data.code == 200) {
        res.data.data.list.forEach(element => {
          this.product_list.push(element)
        });
        if(this.product_list.length >= res.data.data.count) {
          this.finished =  true
        }
        this.countAll = res.data.data.count
      }
    },
    async onSearch () {
      const res = await catezList({
        search_word: this.search_ward,
        type: 3,
        page:1
      })
      if (res.data.code === 200) {
        this.product_list = res.data.data.list
        // this.is_show = false
      }
    },
    // 分页
    productLoad() {
      console.log(this.listObj.page,Math.ceil(this.countAll/10) );
       if(this.listObj.page >= Math.ceil(this.countAll/10) ) {
         this.loading =  false
       }
       this.loading =  true
       setTimeout(() => {
         this.listObj.page++;
        this.getCatezList(this.listObj)
        this.loading =  false
       }, 1000);
      
    },
    downloadApp (id) {
        this.$router.push({path:'/goodsDetail',query:{id:id}})
    }
  }
}
</script>

<style lang="less" scoped>
.school {
  background: #f7f8f9;
  .content {
    height: calc(100vh - 145px);
    .hotRecommend {
      margin-top: 15px;
      .title {
        padding: 0 15px;
        color: #333333;
        font-size: 20px;
        font-weight: bold;
        border-radius: 2px;
        height: 20px;
        display: flex;
        align-items: center;
        .biu {
          width: 4px;
          height: 15px;
          background-color: #2163e9;
          border-radius: 2px;
        }
        .text {
          padding-left: 10px;
        }
      }
      .itemContent {
        // margin-bottom: 10px;
        // margin-top: 10px;
        display: flex;
        justify-content: space-between;
        word-break: break-all;
        flex-wrap: wrap;

        padding: 0 15px; // 剧中
        // columns: 2;
        // column-gap: 10px; // 列间距
        // background-color: #f8f8f8;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        .item {
          display: flex;
          display: inline-block;
          border-radius: 0 0 10px 10px;
          // position: relative;
          // break-inside: avoid;
          background-color: #fff;
          width: 165px;
          margin-bottom: 10px;
          overflow: hidden;
          box-shadow: 0px 3px 6px rgba(0, 96, 240, 0.1);
          .goods {
            width: 100%;
          }
          .eyeIcon {
            position: absolute;
            top: 5px;
            left: 5px;
            background-color: #999;
            color: #fff;
            font-size: 10px;
            padding: 4px 6px;
            border-radius: 9px;
          }
          .goods-info {
            padding: 12px 10px 10px 10px;
            .goods-title {
              font-size: 14px;
              font-weight: 510;
            }
            .goods-user {
              margin-top: 15px;
              display: flex;
              justify-content: space-between;
              font-size: 12px;
              color: #bababa;
              .flex-user-style {
                .img-title {
                  width: 16px;
                  height: 16px;
                  border-radius: 50%;
                  border: 1px solid #ccc;
                  margin-right: 5px;
                }
              }
              .like {
                .good-style {
                  width: 12px;
                  margin-right: 3px;
                }
                .msg {
                  align-items: center;
                }
              }
            }
          }
        }
      }
    }
  }
  ::v-deep .seachStyle {
    font-size: 13px;
    padding: 0 10px;
    border-radius: 18px;
    color: #fff !important;
    background-color: #2163e9 !important;
  }
  ::v-deep .van-search {
    background: none;
  }
  ::v-deep .van-search__content--round {
    background-color: #fff;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  }
  // ::v-deep .van-grid-item {
  //   width: 100px !important;
  // }
  ::v-deep .van-grid-item__content {
    padding: 0;
  }
}
</style>
